import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase";
import Vuelidate from "vuelidate";
import VueScrollTo from 'vue-scrollto';
// import locomotiveScroll from 'locomotive-scroll';
import axios from 'axios';
Vue.prototype.$http = axios;

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

import "./assets/tailwind.css";

// CSS imports

// Atoms
import "./assets/atoms/btn.css";
import "./assets/atoms/page-seperator.css";

// Base
import "./assets/base/base.css";
import "./assets/base/containers.css";

// Molecules
import "./assets/molecules/dashboardQuestions.css";
import "./assets/molecules/dashboardPreformance.css";
import "./assets/molecules/reviewPreformance.css";

// Organisms
import "./assets/organisms/hero.css";
import "./assets/organisms/questionnaires.css";

Vue.use(Vuelidate);

Vue.config.productionTip = false;

Vue.use(VueScrollTo, {
  easing: 'ease-out'
}); // this comes first

const configOptions = {
  apiKey: "AIzaSyDkVtmutdLBdgvCmr9DkeR4FOsyneT964w",
  authDomain: "pdai-api-339413.firebaseapp.com",
  projectId: "pdai-api-339413",
  storageBucket: "pdai-api-339413.appspot.com",
  messagingSenderId: "792728650311",
  appId: "1:792728650311:web:689a5d5f99cf0b3bbfa762"
};

firebase.initializeApp(configOptions);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});

firebase.auth().onAuthStateChanged(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});

// const scroll = new locomotiveScroll();
