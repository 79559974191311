<template>
  <login-form />
</template>

<script>
import LoginForm from "@/views/organisms/LoginForm.vue";

export default {
  name: "Home",

  components: {
    LoginForm,
  },
};
</script>
