import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import firebase from 'firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/registration",
    name: "Registration",

    component: () =>
      import(/* webpackChunkName: "registration" */ "../views/Registration.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    props: true,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/preformancereview",
    name: "PreformanceReview",
    props: true,
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/PreformanceReview.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: () =>
    import(/* webpackChunkName: "dashboard" */ "../views/forgotPassword.vue"),
    beforeEnter(to, from, next) {
      const user = firebase.auth().currentUser;
      if (user) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    },
  },
  {
    path: "/companyinfo",
    name: "Company Info",
    props: true,
    component: () =>
      import(/* webpackChunkName: "CompanyInfo" */ "../views/Registration/CompanyInfo.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/leadershipintro",
    name: "LeadershipIntro",
    props: true,
    component: () =>
      import(/* webpackChunkName: "LeadershipIntro" */ "../views/questionnaires/leadership/LeadershipIntro.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/leadership",
    name: "Leadership",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Leadership" */ "../views/questionnaires/leadership/Leadership.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/leadership-detailed",
    name: "Leadership Detailed",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Leadership" */ "../views/detailed/LeadershipDetailed.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/technologyintro",
    name: "TechnologyIntro",
    props: true,
    component: () =>
      import(/* webpackChunkName: "TechnologyIntro" */ "../views/questionnaires/technology/TechnologyIntro.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/technology",
    name: "Technology",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Technology" */ "../views/questionnaires/technology/Technology.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/technology-detailed",
    name: "Technology Detailed",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Technology" */ "../views/detailed/TechnologyDetailed.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/growthintro",
    name: "GrowthIntro",
    props: true,
    component: () =>
      import(/* webpackChunkName: "GrowthIntro" */ "../views/questionnaires/growth/GrowthIntro.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/growth",
    name: "Growth",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Growth" */ "../views/questionnaires/growth/Growth.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/growth-detailed",
    name: "Growth Detailed",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Growth" */ "../views/detailed/GrowthDetailed.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/barriersintro",
    name: "BarriersIntro",
    props: true,
    component: () =>
      import(/* webpackChunkName: "BarriersIntro" */ "../views/questionnaires/barriers/BarriersIntro.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/barriers",
    name: "Barriers",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Barriers" */ "../views/questionnaires/barriers/Barriers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/barriers-detailed",
    name: "Barriers Detailed",
    props: true,
    component: () =>
      import(/* webpackChunkName: "Barriers" */ "../views/detailed/BarriersDetailed.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/404",
    alias: "*",
    name: "PageNotFound",
    component: () =>
      import(
        /* webpackChunkName: "page-not-found" */ "../views/PageNotFound.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})


router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const currentUser = firebase.auth().currentUser;
  if (requiresAuth && !currentUser) {
    next("/");
  } else if (!requiresAuth && currentUser) {
    next('/dashboard');
  } else {
    next();
  }
});



export default router;
